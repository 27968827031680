export class LruCache<T> {
  private values: Map<string, T> = new Map<string, T>();

  constructor(private maxEntries = 100) { }

  public get(key: string): T | undefined {
    const hasKey = this.values.has(key);
    let entry: T | undefined = undefined;
    if (hasKey) {
      // peek the entry, re-insert for LRU strategy
      entry = this.values.get(key);
      this.values.delete(key);
      if (entry !== void 0) this.values.set(key, entry);
    }

    return entry;
  }

  public put(key: string, value: T) {
    if (this.values.size >= this.maxEntries) {
      // least-recently used cache eviction strategy
      const keyToDelete = this.values.keys().next().value;

      this.values.delete(keyToDelete);
    }

    this.values.set(key, value);
  }
}

export const cache = new LruCache<any>(100);
