import React, { useState, useEffect } from 'react';
import { Slider, colors, makeStyles, Color } from '@material-ui/core';
import SelectedIcon from '@material-ui/icons/CheckOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    colors: {
        display: 'flex',
        flexWrap: 'wrap',
        "& > *": {
            height: theme.spacing(5),
            width: theme.spacing(5),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: theme.transitions.create("boxShadow")
        }
    },
    slider: { padding: theme.spacing(2) },
    selected: {
        boxShadow: theme.shadows[6]
    }
}));

const shades: (keyof Color)[] = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];

const COLORS: (keyof typeof colors)[] = Object.keys(colors).filter(v => v !== "common") as any;

// given a color returns the color and shade
const colorLookup = new Map<string, { color: keyof typeof colors, shadeIndex: number }>();
for (let color of COLORS) {
    for (let i = 0; i < shades.length; i++) {
        colorLookup.set((colors as any)[color][shades[i]], { color, shadeIndex: i });
    }
}

export default function ColorSelector({ value, onChange, disabled }: { value: string, disabled?: boolean, onChange: (newValue: string) => void }) {
    const classes = useStyles();
    const rl = colorLookup.get(value);
    const [shadeIndex, setShadeIndex] = useState<number>(rl?.shadeIndex ?? 0);
    const [color, setColor] = useState<keyof typeof colors>(rl?.color ?? COLORS[0]);

    useEffect(() => {
        if (!rl) return;
        setShadeIndex(rl.shadeIndex);
        setColor(rl.color);
    }, [rl]);

    const rs = shades[shadeIndex];

    return <div className={classes.root}>
        <div className={classes.slider}>
            <Slider
                disabled={disabled}
                value={shadeIndex}
                onChange={(e, newShadeIndex) => {
                    if (Array.isArray(newShadeIndex)) return;
                    setShadeIndex(newShadeIndex as any);
                    onChange((colors as any)[color][shades[newShadeIndex]]);
                }}
                min={0}
                step={1}
                max={shades.length - 1}
            />
        </div>
        <div className={classes.colors}>
            {COLORS.map(key => {
                const c = (colors as any)[key][rs];
                return <div key={key} className={value === c ? classes.selected : ""}
                    onClick={() => { setColor(key); onChange(c); }}
                    style={{ background: c }}>
                    {value === c && <SelectedIcon />}
                </div>
            })}
        </div>
    </div>
}