import React, { useState } from 'react';
import { makeStyles, Container, TextField, Checkbox, CardActions, FormControlLabel, Link, Typography, Collapse, CardContent, Button, Card } from '@material-ui/core';
import * as firebase from 'firebase/app';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import ProgressButton from '../components/ProgressButton';
import useUid from '../hooks/useUid';
import errorCodeMessage from '../utils/errorCodeMessage';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  buttons: {
    justifyContent: 'flex-end'
  },
  form: {
    "& > *": {
      marginBottom: theme.spacing(1)
    }
  },
  logo: { maxWidth: '60%', width: 'auto' },
  loggedIn: {
    position: 'fixed',
    top: theme.spacing(2),
  },
}));

interface Props {
  /** Wher to redirect if registration worked */
  to?: string,
}

export default function RegisterPage({ to = "/places" }: Props) {
  const loc = useLocation();
  const classes = useStyles();
  const [email, setEmail] = useState((loc.state as any)?.email ?? "");
  const [accept, setAccept] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<null | any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const uid = useUid();

  return <Container maxWidth={"xs"} className={classes.root}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>

    </div>
    <Typography variant="h4">Attract more visitors</Typography>

    {uid && !loading && <Card className={classes.loggedIn}>
      <CardContent>Sie sind breits angemeldet</CardContent>
      <CardActions>
        <Button onClick={() => firebase.auth().signOut()}>Abmelden</Button>
        <Button component={RouterLink} to={to}>Meine Places</Button>
      </CardActions>
    </Card>}

    <form className={classes.form} onSubmit={async (e) => {
      setLoading(true)
      try {
        e.preventDefault();
        e.stopPropagation();
        if (password2 !== password) {
          //TODO: show error
          return;
        }
        let value = await firebase.auth().createUserWithEmailAndPassword(email, password);
        try {
          await value.user?.sendEmailVerification();
        } catch (err) {
          console.error(err);
        }
        history.replace((loc.state as any)?.from ?? to);
      } catch (e) {
        firebase.analytics().logEvent('failed_registration', {
          message: e.message ?? "",
          stack: e.stack ?? ""
        });
        setError(e);
      } finally {
        setLoading(false);
      }
    }}>
      <TextField
        label="Email"
        value={email}
        fullWidth
        onChange={e => setEmail(e.target.value)}
        autoFocus={!email}
      />
      <TextField
        autoFocus={!!email}
        label="Passwort"
        value={password}
        type="password"
        fullWidth
        onChange={e => setPassword(e.target.value)}
      />
      <TextField
        label="Passwort wiederholen"
        value={password2}
        fullWidth
        type="password"
        onChange={e => setPassword2(e.target.value)}
      />
      <FormControlLabel
        control={<Checkbox
          checked={accept}
          onChange={(e, c) => setAccept(c)}
          name="checkedB"
          color="primary"
        />}
        label={<span>Akzeptiere <Link href="https://clye.app/agb" target="blank">AGBs</Link> und <Link href="https://clye.app/datenschutz" target="blank">Datenschutz</Link></span>}
      />
      <Collapse in={!!error}>
        <Typography variant="subtitle1" color="error">{errorCodeMessage[error?.code] ?? error?.message ?? ""}</Typography>
      </Collapse>
      <CardActions className={classes.buttons}>
        <ProgressButton
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          disabled={!(accept && password === password2)}
          loading={loading}
        >Registrieren</ProgressButton>
      </CardActions>
    </form>
  </Container>
}
