import React from 'react';
import { makeStyles, TextField, Grid, Typography, Collapse } from '@material-ui/core';
import errorCodeMessage from '../utils/errorCodeMessage';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  form: {
    //marginBottom: theme.spacing(1),
  }
}));

interface EmailAndPassword {
  email: string,
  password: string
}

export default function SignInForm({ error, value, onChange }: { error?: any, onChange: (newValue: EmailAndPassword) => void, value: EmailAndPassword }) {
  const classes = useStyles();

  return <>
    <Grid className={classes.form} container spacing={1}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          label="Email"
          fullWidth
          value={value.email}
          onChange={(e) => onChange({ ...value, email: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Passwort"
          type="password"
          fullWidth
          value={value.password}
          onChange={(e) => onChange({ ...value, password: e.target.value })}
        />
      </Grid>
    </Grid>
    <Collapse in={!!error}>
      <Typography variant="subtitle1" color="error">{errorCodeMessage[error?.code] ?? error?.message ?? ""}</Typography>
    </Collapse>
  </>
}
