import { useState, useEffect, useRef, useCallback } from "react";
import * as firebase from 'firebase/app';
import { cache } from "../utils/Lru";

export default function useUpdFirestoreDoc<T extends object>(
  fn: (firestore: firebase.firestore.Firestore) => firebase.firestore.DocumentReference<T> | undefined | null,
  deps: React.DependencyList
): [T | undefined, (data: Partial<T>) => Promise<void>, firebase.firestore.DocumentReference<T> | null | undefined] {
  const [res, setRes] = useState<T>(() => {
    let ref = fn(firebase.firestore());
    if (!ref?.path) return null;
    return cache.get("docData#" + ref?.path)
  });
  const docRef = useRef<undefined | null | firebase.firestore.DocumentReference<T>>(undefined);
  useEffect(() => {
    let ref = fn(firebase.firestore());
    docRef.current = ref;
    if (ref)
      return ref.onSnapshot((snap) => {
        let path = ref?.path;
        if (path) cache.put("docData#" + path, snap.data());
        setRes(snap.data() as any);
      }, err => console.error(err));
  }, deps);
  const upd = useCallback(async (upd: Partial<T>): Promise<void> => {
    setRes(orig => ({ ...orig, ...upd } as any));
    return docRef.current?.set(upd as any, { merge: true });
  }, []);
  return [res, upd, docRef.current];
}
