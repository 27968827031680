/* eslint-disable */
import { firestore } from "firebase/app";
import notEmpty from "./notEmpty";

export class GeoFirePoint {
  constructor(public readonly latitude: number, public readonly longitude: number) { }
  /// return geographical distance between two Co-ordinates
  static distanceBetween(
    to: GeoFirePoint, from: GeoFirePoint) {
    return distance(to, from);
  }

  static from({ longitude, latitude }: { latitude: number, longitude: number }) {
    return new GeoFirePoint(latitude, longitude);
  }

  /// return neighboring geo-hashes of [hash]
  static neighborsOf(hash: string) {
    return neighbors(hash);
  }

  /// return hash of [GeoFirePoint]
  hash() {
    return encode(this.latitude, this.longitude, 9);
  }

  /// return all neighbors of [GeoFirePoint]
  neighbors(): string[] {
    return neighbors(this.hash());
  }

  /// return [GeoPoint] of [GeoFirePoint]
  geoPoint(): firestore.GeoPoint {
    return new firestore.GeoPoint(this.latitude, this.longitude);
  }

  /// return distance between [GeoFirePoint] and ([lat], [lng])
  distance(lat: number, lng: number) {
    return GeoFirePoint.distanceBetween(this, new GeoFirePoint(lat, lng));
  }

  data() {
    return { 'geopoint': this.geoPoint(), 'geohash': this.hash() };
  }

  /// haversine distance between [GeoFirePoint] and ([lat], [lng])
  haversineDistance(lat: number, lng: number) {
    return GeoFirePoint.distanceBetween(this, new GeoFirePoint(lat, lng));
  }
}

export class DistanceDocSnapshot<T> {
  constructor(public readonly documentSnapshot: firestore.DocumentSnapshot<T>, public distance: number | null) { }
}

export class GeoFireCollectionRef<T> {
  constructor(public readonly _collectionReference: firestore.Query<T>) { }

  /// query firestore documents based on geographic [radius] from geoFirePoint [center]
  /// [field] specifies the name of the key in the document
  async within({ center, radius, field, strictMode = false }:
    {
      center: GeoFirePoint,
      radius: number,
      field: string,
      strictMode: boolean
    }): Promise<firestore.DocumentSnapshot<T>[]> {
    const precision = setPrecision(radius);
    const centerHash = center.hash().substring(0, precision);
    const area = GeoFirePoint.neighborsOf(centerHash);
    area.push(centerHash);

    const queries = await Promise.all(area.map(hash => {
      var tempQuery = this._queryPoint(hash, field);
      return tempQuery.get()
        .then((querySnapshot: firestore.QuerySnapshot<T>) => {
          return querySnapshot.docs.map((element) => new DistanceDocSnapshot<T>(element, null));
        });
    }));

    return queries.map((list: DistanceDocSnapshot<T>[]) => {
      const mappedList = list.map((distanceDocSnapshot: DistanceDocSnapshot<T>) => {
        // split and fetch geoPoint from the nested Map
        const fieldList = field.split('.');
        var geoPointField = (distanceDocSnapshot.documentSnapshot.data() as any)[fieldList[0]];
        if (fieldList.length > 1) {
          for (let i = 1; i < fieldList.length; i++) {
            geoPointField = geoPointField[fieldList[i]];
          }
        }
        if (!geoPointField) return null;
        const geoPoint = geoPointField['geopoint'];
        distanceDocSnapshot.distance = center.distance(geoPoint.latitude, geoPoint.longitude);
        return distanceDocSnapshot;
      }).filter(notEmpty);

      var filteredList = strictMode
        ? mappedList.filter((doc: DistanceDocSnapshot<T>) => {
          const distance = doc.distance ?? 0;
          return distance <= radius * 1.02; // buffer for edge distances;
        })
        : mappedList;
      filteredList.sort((a, b) => {
        let distA = a.distance ?? 0;
        let distB = b.distance ?? 0;
        let val = Math.round(distA * 1000) - Math.round(distB * 1000);
        return val;
      });
      return filteredList.map((element) => element.documentSnapshot);
    }).reduce((pv, cv) => {
      pv.push(...cv);
      return pv;
    });
  }

  /// construct a query for the [geoHash] and [field]
  private _queryPoint(geoHash: string, field: string) {
    let end = `${geoHash}~`;
    return this._collectionReference.orderBy(`${field}.geohash`)
      .startAt(geoHash)
      .endAt(end);
  }
}

export const BASE32_CODES = '0123456789bcdefghjkmnpqrstuvwxyz';
export const base32CodesDic = new Map<string, number>();

for (var i = 0; i < BASE32_CODES.length; i++) {
  base32CodesDic.set(BASE32_CODES[i], i);
}

var encodeAuto = 'auto';

///
/// Significant Figure Hash Length
///
/// This is a quick and dirty lookup to figure out how long our hash
/// should be in order to guarantee a certain amount of trailing
/// significant figures. This was calculated by determining the error:
/// 45/2^(n-1) where n is the number of bits for a latitude or
/// longitude. Key is # of desired sig figs, value is minimum length of
/// the geohash.
/// @type Array
// Desired sig figs:    0  1  2  3   4   5   6   7   8   9  10
var sigfigHashLength = [0, 5, 7, 8, 11, 12, 13, 15, 16, 17, 18];

///
/// Encode
/// Create a geohash from latitude and longitude
/// that is 'number of chars' long
function encode(latitude: any, longitude: any, numberOfChars: number | "auto") {
  if (numberOfChars === encodeAuto) {
    if (typeof latitude.runtimeType === 'number' || typeof longitude.runtimeType === 'number') {
      throw new Error('string notation required for auto precision.');
    }
    let decSigFigsLat = latitude.split('.')[1].length;
    let decSigFigsLon = longitude.split('.')[1].length;
    let numberOfSigFigs = Math.max(decSigFigsLat, decSigFigsLon);
    numberOfChars = sigfigHashLength[numberOfSigFigs];
  } else if (numberOfChars === null) {
    numberOfChars = 9;
  }

  var chars: string[] = [], bits = 0, bitsTotal = 0, hashValue = 0;
  let maxLat = 90, minLat = -90, maxLon = 180, minLon = -180, mid;

  while (chars.length < numberOfChars) {
    if (bitsTotal % 2 === 0) {
      mid = (maxLon + minLon) / 2;
      if (longitude > mid) {
        hashValue = (hashValue << 1) + 1;
        minLon = mid;
      } else {
        hashValue = (hashValue << 1) + 0;
        maxLon = mid;
      }
    } else {
      mid = (maxLat + minLat) / 2;
      if (latitude > mid) {
        hashValue = (hashValue << 1) + 1;
        minLat = mid;
      } else {
        hashValue = (hashValue << 1) + 0;
        maxLat = mid;
      }
    }

    bits++;
    bitsTotal++;
    if (bits === 5) {
      var code = BASE32_CODES[hashValue];
      chars.push(code);
      bits = 0;
      hashValue = 0;
    }
  }

  return chars.join('');
}

///
/// Decode Bounding box
///
/// Decode a hashString into a bound box that matches it.
/// Data returned in a List [minLat, minLon, maxLat, maxLon]
function decodeBbox(hashString: string) {
  var isLon = true;
  let maxLat = 90, minLat = -90, maxLon = 180, minLon = -180, mid;

  var hashValue = 0;
  for (var i = 0, l = hashString.length; i < l; i++) {
    var code = hashString[i].toLowerCase();
    hashValue = base32CodesDic.get(code) ?? 0;

    for (var bits = 4; bits >= 0; bits--) {
      var bit = (hashValue >> bits) & 1;
      if (isLon) {
        mid = (maxLon + minLon) / 2;
        if (bit === 1) {
          minLon = mid;
        } else {
          maxLon = mid;
        }
      } else {
        mid = (maxLat + minLat) / 2;
        if (bit === 1) {
          minLat = mid;
        } else {
          maxLat = mid;
        }
      }
      isLon = !isLon;
    }
  }
  return [minLat, minLon, maxLat, maxLon];
}

///
/// Decode a [hashString] into a pair of latitude and longitude.
/// A map is returned with keys 'latitude', 'longitude','latitudeError','longitudeError'
function decode(hashString: string) {
  let bbox = decodeBbox(hashString);
  let lat = (bbox[0] + bbox[2]) / 2;
  let lon = (bbox[1] + bbox[3]) / 2;
  let latErr = bbox[2] - lat;
  let lonErr = bbox[3] - lon;
  return {
    'latitude': lat,
    'longitude': lon,
    'latitudeError': latErr,
    'longitudeError': lonErr,
  };
}

///
/// Neighbor
///
/// Find neighbor of a geohash string in certain direction.
/// Direction is a two-element array, i.e. [1,0] means north, [-1,-1] means southwest.
///
/// direction [lat, lon], i.e.
/// [1,0] - north
/// [1,1] - northeast
export function neighbor(hashString: string, direction: any): string {
  var lonLat = decode(hashString);
  var neighborLat =
    lonLat['latitude'] + direction[0] * lonLat['latitudeError'] * 2;
  var neighborLon =
    lonLat['longitude'] + direction[1] * lonLat['longitudeError'] * 2;
  return encode(neighborLat, neighborLon, hashString.length);
}

///
/// Neighbors
/// Returns all neighbors' hashstrings clockwise from north around to northwest
/// 7 0 1
/// 6 X 2
/// 5 4 3
export function neighbors(hashString: string): string[] {
  let hashStringLength = hashString.length;
  var lonlat = decode(hashString);
  let lat = lonlat['latitude'];
  let lon = lonlat['longitude'];
  let latErr = lonlat['latitudeError'] * 2;
  let lonErr = lonlat['longitudeError'] * 2;

  var neighborLat, neighborLon;

  function encodeNeighbor(neighborLatDir: any, neighborLonDir: any) {
    neighborLat = lat + neighborLatDir * latErr;
    neighborLon = lon + neighborLonDir * lonErr;
    return encode(neighborLat, neighborLon, hashStringLength);
  }

  var neighborHashList = [
    encodeNeighbor(1, 0),
    encodeNeighbor(1, 1),
    encodeNeighbor(0, 1),
    encodeNeighbor(-1, 1),
    encodeNeighbor(-1, 0),
    encodeNeighbor(-1, -1),
    encodeNeighbor(0, -1),
    encodeNeighbor(1, -1)
  ];

  return neighborHashList;
}

function setPrecision(km: number): number {
  /*
   * 1 ≤ 5,000km × 5,000km
   * 2 ≤ 1,250km × 625km
   * 3 ≤ 156km   × 156km
   * 4 ≤ 39.1km  × 19.5km
   * 5 ≤ 4.89km  × 4.89km
   * 6 ≤ 1.22km  × 0.61km
   * 7 ≤ 153m    × 153m
   * 8 ≤ 38.2m   × 19.1m
   * 9 ≤ 4.77m   × 4.77m
   */

  if (km <= 0.00477)
    return 9;
  else if (km <= 0.0382)
    return 8;
  else if (km <= 0.153)
    return 7;
  else if (km <= 1.22)
    return 6;
  else if (km <= 4.89)
    return 5;
  else if (km <= 39.1)
    return 4;
  else if (km <= 156)
    return 3;
  else if (km <= 1250)
    return 2;
  else
    return 1;
}

export const MAX_SUPPORTED_RADIUS = 8587;

// Length of a degree latitude at the equator
export const METERS_PER_DEGREE_LATITUDE = 110574;

// The equatorial circumference of the earth in meters
export const EARTH_MERIDIONAL_CIRCUMFERENCE = 40007860;

// The equatorial radius of the earth in meters
export const EARTH_EQ_RADIUS = 6378137;

// The meridional radius of the earth in meters
export const EARTH_POLAR_RADIUS = 6357852.3;

/* The following value assumes a polar radius of
   * r_p = 6356752.3
   * and an equatorial radius of
   * r_e = 6378137
   * The value is calculated as e2 === (r_e^2 - r_p^2)/(r_e^2)
   * Use exact value to avoid rounding errors
   */
export const EARTH_E2 = 0.00669447819799;

// Cutoff for floating point calculations
export const EPSILON = 1e-12;

export function distance(location1: GeoFirePoint, location2: GeoFirePoint) {
  return calcDistance(location1.latitude, location1.longitude,
    location2.latitude, location2.longitude);
}

export function calcDistance(
  lat1: number, long1: number, lat2: number, long2: number) {
  // Earth's mean radius in meters
  const radius = (EARTH_EQ_RADIUS + EARTH_POLAR_RADIUS) / 2;
  let latDelta = _toRadians(lat1 - lat2);
  let lonDelta = _toRadians(long1 - long2);

  let a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
    (Math.cos(_toRadians(lat1)) *
      Math.cos(_toRadians(lat2)) *
      Math.sin(lonDelta / 2) *
      Math.sin(lonDelta / 2));
  let distance = radius * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) / 1000;
  return parseFloat(distance.toFixed(3));
}

function _toRadians(num: number) {
  return num * (Math.PI / 180.0);
}
