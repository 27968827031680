import React, { useState, useEffect } from 'react';
import { makeStyles, Container, Button, CardActions, Snackbar } from '@material-ui/core';
import * as firebase from 'firebase/app';
import { useHistory, Link, useLocation } from 'react-router-dom';
import SignInForm from '../components/SignInForm';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  logo: { maxWidth: '60%', width: 'auto' },
  buttons: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  form: {
    marginBottom: theme.spacing(1),
  }
}));

interface Props {
  /** The url to redirect to using react router */
  to?: string
}

export default function LoginPage({ to = "/places" }: Props) {
  const classes = useStyles();

  const [input, setInput] = useState({ email: "", password: "" });
  const [error, setError] = useState<null | any>(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const loc = useLocation();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) history.replace((loc.state as any)?.from ?? to);
    })
  }, [loc, history]);

  const login = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    firebase.auth().signInWithEmailAndPassword(input.email.trim(), input.password).then(() => {
      history.replace((loc.state as any)?.from ?? to);
    }, (e) => {
      firebase.analytics().logEvent('failed_login', {
        message: e.message ?? "",
        stack: e.stack ?? ""
      });
      setError(e)
    })
  };

  const forgotPassword = () => {
    firebase.auth().sendPasswordResetEmail(input.email, { url: window.location.href })
      .then(() => setOpen(true))
      .catch(setError);
  }

  return <Container maxWidth="xs" className={classes.root}>
    <form onSubmit={login}>
      <SignInForm value={input} onChange={setInput} error={error} />
      <CardActions className={classes.buttons}>
        <Button onClick={forgotPassword}>Passwort Vergessen</Button>
      </CardActions>
      <CardActions className={classes.buttons}>
        <Button variant="outlined" component={Link} to={{
          pathname: "/register",
          state: { ...(loc.state as any), email: input.email }
        }}>Registrieren</Button>
        <Button variant="contained" color="primary" type="submit" disableElevation>Anmelden</Button>
      </CardActions>
    </form>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      message="Reset Email Wurde gesendet"
    />
  </Container>
}
