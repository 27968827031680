/* eslint-disable */
import { useState, useEffect } from "react";
import * as firebase from 'firebase/app';

export default function useUid(): string | null {
  const [uid, setUid] = useState<string | null>(null);
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setUid(user?.uid ?? null);
    });
  }, []);
  return uid;
}
