import { useState, useEffect, useCallback } from "react";

const listeners: ((v: any) => void)[] = [];

/**
 * save some state in local storage so it can be used conveniently throughout the application. It
 * will also make sure that other tabs have the same state for the key.
 * @param key 
 * @param initialValue 
 */
export default function useLocalStorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [value, setValue] = useState<T>(() => {
        let item = localStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        }
        return initialValue;
    });

    useEffect(() => {
        listeners.push(setValue);
        let item = localStorage.getItem(key);
        if (item) {
            setValue(JSON.parse(item));
        } else {
            setValue(initialValue);
        }

        const el = (event: StorageEvent) => {
            if (event.key === key && event.newValue) {
                setValue(JSON.parse(event.newValue));
            }
        };
        window.addEventListener('storage', el);
        return () => {
            window.removeEventListener("storage", el);
            listeners.splice(listeners.indexOf(setValue), 1);
        }
    }, [key]); /* eslint-disable-line */

    const set: React.Dispatch<React.SetStateAction<T>> = useCallback((value: React.SetStateAction<T>) => {
        if (typeof value === "function") {
            setValue(v => {
                const nv = (value as any)(v);
                localStorage.setItem(key, JSON.stringify(nv));
                for (let l of listeners) l(nv);
                return nv;
            });
        } else {
            setValue(value as any);
            localStorage.setItem(key, JSON.stringify(value));
            for (let l of listeners) l(value);
        }
    }, [key]);

    return [value, set];
}
