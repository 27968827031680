const errorCodeMessage: { [code: string]: string } = {
    "auth/invalid-email": "Die Email-Addresse ist fehlerhaft.",
    "auth/weak-password": "Das Passwort muss aus mindestens 6 Zeichen bestehen.",
    "auth/email-already-in-use": "Die Emailaddresse wird bereits verwendet.",
    "auth/user-not-found": "Kein Nutzer mit dieser Email-Addresse registriert.",
    "auth/wrong-password": "Das Passwort ist falsch.",
    "auth/network-request-failed": "Bitte überprüfe deine Internetverbindung. Möglicherweise blockiert auch ein AddBlocker oder ähnliches die Verbindung.",
    "auth/too-many-requests": "Wir haben zu viele Anfragen von diesem gerät registriert. Bitte mache eine Pause!",
    "auth/user-disabled": "Dein Account wurde disabled oder gelöscht. Bitte kontaktieren den Support.",
    "auth/requires-recent-login": "Bitte melde dich erneut an und versuche es noch einmal.",
    "auth/email-already-exists": "Die Emailadresse wird bereits verwendet.",
    "auth/phone-number-already-exists": "Die Telefonnummer wird bereits verwendent.",
    "auth/invalid-phone-number": "Die Telefonnummer ist fehlerhaft.",
    "auth/cannot-delete-own-user-account": "Du kannst nicht deinen eigenen Account löschen.",
}

export default errorCodeMessage;