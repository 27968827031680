import { useState, useEffect, useRef } from "react";
import React from "react";
import TextField from "@material-ui/core/TextField";

const GERMAN_REGEX = /^([0-9]{1,2})\.([0-9]{1,2})\.([0-9]{4})$/;
const ISO_REGEX = /^([0-9]{4})-([0-9]{2})-([0-9]{2})/;

function toISO(inp: string | undefined) {
    if (!inp) return inp;
    let match = inp.match(GERMAN_REGEX);
    if (!match) return void 0;
    return `${match[3]}-${match[2].padStart(2, '0')}-${match[1].padStart(2, '0')}`;
}

function toGerman(inp: string | undefined) {
    if (!inp) return inp;
    let match = inp.match(ISO_REGEX);
    if (!match) return void 0;
    return `${match[3]}.${match[2]}.${match[1]}`;
}

export default function DateInput({ label, value, onChange }: { label?: React.ReactChild, value?: string, onChange: (newValue: string | undefined) => void }) {
    let [v, setV] = useState(toGerman(value));
    const ref = useRef(value);
    useEffect(() => {
        if (value === ref.current) return;
        const nv = toGerman(value);
        setV(nv);
    }, [value]);

    return <TextField fullWidth label={label ?? "Geburtsdatum"}
        value={v ?? ""}
        onChange={(e) => {
            let nv = e.target.value;
            setV(nv);
            if (nv.match(GERMAN_REGEX)) {
                let res = toISO(nv) ?? "";
                ref.current = res;
                onChange(res);
            } else if (nv === "") {
                onChange(undefined);
            }
        }}
        InputProps={{ pattern: "^[0-9]{3}.[0-9]{2}.[0-9]{4}$" } as any}
        error={!!v && !v?.match(GERMAN_REGEX)}
        placeholder="DD.MM.YYYY"
    />;
}
