import { useState, useEffect } from "react";
import * as firebase from 'firebase/app';

export default function useFirestore<T>(
  fn: (firestore: firebase.firestore.Firestore) => firebase.firestore.Query<T> | undefined | null,
  deps: React.DependencyList
): firebase.firestore.QuerySnapshot<T> | undefined {
  const [res, setRes] = useState<firebase.firestore.QuerySnapshot<T>>();
  useEffect(() => {
    let ref = fn(firebase.firestore());
    if (ref)
      return ref.onSnapshot((snap) => {
        setRes(snap);
      }, err => console.error(err));
  }, deps);
  return res;
}
